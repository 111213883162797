<template>
  <div>
    <div class="whiteborder">
      <!-- <div class="sideRegisterBtn d-none d-lg-block" v-show="$route.name != 'register'"><nuxt-link to="/register/">register now</nuxt-link></div> -->
      <div class="sideRegisterBtn d-none d-lg-block" v-show="$route.name != 'register'"><a :href="`/register/${form.sourceQuery}`">register now</a></div>
      <app-header />
      <nuxt class="nuxt-content"/>
      <!-- <blog-footer-carousel /> -->
    </div>
    <app-footer />
    <!-- <app-loading v-show="$route.name == 'index'"/> -->
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import BlogFooterCarousel from '@/components/BlogFooterCarousel';
import AppLoading from '@/components/AppLoading';

import AppFooter from '@/components/AppFooter';
export default {
  data() {
      return {
          form: {
              sourceQuery:""
          }
      }
  },
  components:{
    AppHeader,
    AppFooter,
    BlogFooterCarousel,
    AppLoading
  },

  mounted(){
      var vm = this;
      this.$nextTick(() => {
        window.onscroll = function(e){
            vm.triggerViewPortAnimation()
        }
        this.getPageQuery();
      });
  },
  methods:{
    triggerViewPortAnimation(){
        var viewport = Array.from(document.querySelectorAll('.in-viewport')),
            nuxtcontent = document.querySelector('.nuxt-content')


        var t2 = new TimelineLite({paused:true, onComplete:''})

        t2.addLabel('start')
          .to(nuxtcontent, 1, {autoAlpha: 1}, "start")
          .staggerTo(viewport, 1, {
            y: 0, 
            scaleY: 1, 
            autoAlpha: 1, 
            transformOrigin: "50% 0%",
            ease: Power3.easeOut,
            className: '+=done',
            delay: 0.2
            }, .1, 'start+=.2')
        t2.play()
    },
    triggerHomeAnimation(callback){
        var done = callback;
        var landingpage = document.querySelector(".landingpage"),
            landingpageImage = document.querySelector(".landingpage img"),
            landingpageTitle = document.querySelector(".landingpage .flexFont"),
            landingpageParagraph = document.querySelectorAll(".landingpage p"),
            bubble = document.querySelector('.bubble-svg'),
            nuxtContent = document.querySelector('.nuxt-content')

        var t2 = new TimelineLite({paused:true, onComplete:done})
        t2.addLabel("delayed")
          .set([landingpage, bubble], {autoAlpha: 0})
          .set(landingpageImage, {autoAlpha: 0, y:50})
          .set(landingpageTitle, {autoAlpha: 0, y:50})
          .set(landingpageParagraph, {autoAlpha: 0, y:50})
          .set(landingpage, {autoAlpha: 0})
          .to([nuxtContent, landingpage], 0.6, {autoAlpha: 1})
          .to(landingpageImage, .6,{y:0,autoAlpha: 1,ease:Expo.easeInOut}, .3)
          .to(landingpageTitle, .6,{y:0,autoAlpha: 1,ease:Expo.easeInOut}, .5)
          .to(landingpageParagraph, .6,{y:0,autoAlpha: 1,ease:Expo.easeInOut}, .8)
          .addLabel("bubble")
          .to(bubble, .6,{y:0,autoAlpha: 1,ease:Expo.easeInOut}, .8, "bubble+=.3")
        t2.play();
    },
    triggerBlogEnterAnimation(callback){
      var done = callback;
      var title = document.getElementsByTagName('h1'),
          blogdate = document.querySelectorAll('.blog-date'),
          blogcontent = Array.from(document.querySelectorAll('.blog-content p, .blog-post img, .blog-post a')),
          nuxtContent = document.querySelector('.nuxt-content')

       var t1 = new TimelineLite({paused: true, onComplete: done})

      t1.addLabel("start")
        .set([title,blogdate], { x: -100,autoAlpha: 0})
        .set(blogcontent, {autoAlpha: 0, y:20})
        .to(nuxtContent, 1, {autoAlpha: 1})
        .to(title, .8, { x: 0,ease: Expo.easeInOut, autoAlpha: 1}, "start")
        .to(blogdate, .8, { x: 0,ease: Expo.easeInOut, autoAlpha: 1}, "start")
        .to(blogcontent, .8,{autoAlpha: 1, y:0}, "start")
      t1.play()
    },
    triggerBlogLeaveAnimation(callback){
      var done = callback;
      var title = document.getElementsByTagName('h1'),
          blogdate = document.querySelector('.blog-date'),
          blogcontent = document.querySelectorAll('.blog-content p, .blog-post img, .blog-post a'),
          nuxtContent = document.querySelector('.nuxt-content')

      var t1 = new TimelineLite({paused: true, onComplete: done})
      
      t1.addLabel("start")
        .set(title, {x: 0,autoAlpha: 1})
        .set(blogcontent, {autoAlpha: 1})
        .to(title, .6, { x: -100,ease: Expo.easeIn,autoAlpha: 0}, "start")
        .to(blogdate, .5,{ x: -100,ease: Expo.easeIn, autoAlpha: 0}, "start")
        .to(blogcontent, .3,{autoAlpha: 0, y:20 }, "start")

      t1.play()
    },
    getPageQuery() {
        this.form.sourceQuery = localStorage.getItem("sourceQuery");
    }
  }
}
</script>
<style>
 html, body {
   scroll-behavior: smooth;
 }
</style>
