<template>
  <div class="component loader">
    <div>
      <b-container class="mt-0">
        <b-row>
          <b-col md="12" class="text-center loader__headline">
            <b-img src="/img/icons/newseaton-logotext.svg" fluid center alt="New Seaton | Community Built From The Ground Up" style="opacity:0" width="400"/>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="loader__line">
    </div>
    <div class="home__filler"></div>
  </div>
</template>

<script>
  import {
    TimelineLite
  } from 'gsap'
  export default {
    mounted() {
      var vm = this;
      var $loader = document.getElementsByClassName('loader'),
          $loader_line = document.getElementsByClassName('loader__line'),
          $loader__headline = Array.from(document.querySelectorAll(".loader__headline span")),
          $loader__headline1 = document.querySelector(".loader__headline img"),
          $homefiller = document.querySelector(".home__filler"),
          $sideRegisterBtn = document.querySelector('.sideRegisterBtn')

      //TIMELINE
      var tl = new TimelineLite({
        paused: true,
        onComplete: function () {
          vm.$store.commit('endAnimationScreen', false)
        }
      });
      tl.addLabel("start")
        .set($loader__headline, {autoAlpha: 0})
        .set($loader_line, {scaleX: 0,autoAlpha: 1})
        .set($loader__headline1, {autoAlpha: 0, y: -100})
        .set($sideRegisterBtn, {x:-100})
        .to($loader__headline1, .8, {autoAlpha: 1,y: 0, ease: Expo.easeInOut}, "start")
        .to($loader_line, 0.6, {scaleX: 1,transformOrigin: "0% 50%",ease: Expo.easeInOut})
        .addLabel("collapse")
        .to($loader_line, 0.8, {top: 0,ease: Expo.easeInOut}, "collapse")
        .to($loader_line, 0.8, {yPercent:-100,ease: Expo.easeInOut}, "collapse+=.8")
        .to($loader__headline1, 1, {autoAlpha: 0,y: -100,ease: Expo.easeInOut}, "collapse-=.1")
        .set($loader__headline, {autoAlpha: 0})
        .set($loader, {autoAlpha: 0})
        .to($sideRegisterBtn, .5,{x:0}, "collapse+=2")
      tl.play();
    }
  }

</script>

<style scoped lang="scss">

</style>
