<template>
  <div class="background--white carouselbackground">
    <b-container class="py-5 mt-0 carouselcontainer">
      <b-row>
        <b-col>
          <!-- <hr> -->
          <p
            class="text--red text--extrabold text-center text-uppercase"
            style="font-size: 30px"
          >
            Explore our blog..
          </p>
          <div class="blogfootercarousel">
            <slick ref="slick" :options="slickOptions">
              <div
                v-for="(blog, val) in blogs"
                :key="blog.title"
                class="blog-column"
              >
                <nuxt-link
                  :to="{ path: `/blogs/` + val + '/' }"
                  :title="blog.title"
                  class="table"
                >
                  <div class="imageoverlay">
                    <b-img
                      :src="'/img/blogs/' + blog.img"
                      :alt="blog.alt"
                      center
                      fluid
                    />
                  </div>
                  <p class="mb-2 mt-5 blog-title">
                    <span class="text-left">{{
                      blog.title | truncate(30, "...")
                    }}</span
                    ><span class="float-right d-none">{{ blog.date }}</span>
                  </p>
                  <div class="blog-divider"></div>
                  <p class="blog-description">
                    {{ blog.description | truncate(45, "...") }}
                  </p>
                </nuxt-link>
              </div>
            </slick>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import blogs_json from "@/json/blogs.json";
import { kebabify } from "@/plugins/helpers";

export default {
  data() {
    return {
      blogs: [],
      slickOptions: {
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              arrows: false,
              centerMode: true,
              centerPadding: "20px"
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
              centerMode: true,
              infinite: true,
              centerPadding: "20px"
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
              infinite: true,
              centerMode: true,
              centerPadding: "20px"
            }
          }
        ]
      }
    };
  },
  beforeMount() {
    this.blogs = blogs_json;
  },
  mounted() {
    this.$store.dispatch("increaseBlogsShown", 4);
  },
  methods: {
    kebabify,
    loadMore() {
      this.$store.dispatch("increaseBlogsShown", 4).then(() => {
        console.log(
          $(".blogfootercarousel .row .blog-column:nth-last-child(4)").offset()
            .top
        );
        $("html, body").animate(
          {
            scrollTop: $(
              ".blogfootercarousel .row .blog-column:nth-last-child(4)"
            ).offset().top
          },
          1000
        );
      });
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
