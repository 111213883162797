<template>
  <footer>
    <b-container class="mt-0">
      <b-row>
        <b-col md="7" class="presentation_centre">
          <!-- <p class="mb-0"><b>PRESENTATION CENTRE:</b></p>
                    <p style="color:#E0C223">TEMPORARILY CLOSED</p> -->
          <!-- <p>
                        <a href="https://goo.gl/maps/6Jkf8ajjGqT89JGf9" target="_blank">2820 SIDELINE 26, PICKERING</a>
                    </p> -->
          <!-- <p>BY APPOINTMENT ONLY</p> -->
          <!-- <p style="color:#E0C223">TEMPORARILY CLOSED</p> -->
          <!-- <p style="color:#E0C223">HOLIDAY HOURS: </p> -->
          <!-- <p style="color:#E0C223">BROOKFIELD WILL REOPENING IN THE NEW YEAR</p> -->
          <p class="mb-0">
            <b
              >Brookfield Residental
              <span style="color:#E0C223">- NOW OPEN</span></b
            >
          </p>
          <p style="color:#E0C223">
            Saturday: 12-5 pm <br />Contact
            <b
              ><a href="mailto:newseaton@brookfieldrp.com"
                >newseaton@brookfieldrp.com</a
              ></b
            >
          </p>
          <p class="mb-0">
            <b>Aspen Ridge<span style="color:#E0C223"> - NOW OPEN</span></b>
          </p>
          <p style="color:#E0C223">
            Saturday & Sunday: 12-5 pm<br />
            Monday to Friday: By Appointment<br />
            <b
              ><a href="mailto:NSSales@aspenridgehomes.com"
                >NSSales@aspenridgehomes.com</a
              ></b
            >
          </p>
          <p class="mb-0">
            <b>Tower Hill<span style="color:#E0C223"> - NOW OPEN</span></b>
          </p>
          <p style="color:#E0C223">
            Saturday & Sunday: 12-5 pm<br />
            Monday to Friday: By Appointment<br />
            Contact
            <b><a href="mailto:sales@towerhill.ca">sales@towerhill.ca</a></b>
          </p>
          <!-- <p style="color:#E0C223; "><b><nuxt-link to="/register" style="text-decoration: underline">Register Now for Updates</nuxt-link></b></p> -->

          <!-- <p class="mb-0"><b>Aspen Ridge  <span style="color:#E0C223">- Coming 2024</span></b></p>
                    <p style="color:#E0C223">Contact <b>nssales@aspenridgehomes.com</b></p>
                    <br /><br /> -->
          <div class="d-none d-md-block">
            <b-row>
              <b-col sm="4">
                <a href="http://www.aspenridgehomes.com/" target="_blank">
                  <b-img
                    src="/img/aspenridgehomesv2.png"
                    fluid
                    center
                    alt="Aspen Ridge Homes"
                  />
                </a>
              </b-col>
              <b-col sm="4">
                <a
                  href="https://www.brookfieldresidential.com/new-homes/ontario/greater-toronto-area/pickering/new-seaton"
                  target="_blank"
                >
                  <b-img
                    src="/img/brookfieldhomes.png"
                    fluid
                    center
                    alt="Brookfield Homes"
                  />
                </a>
              </b-col>
              <b-col sm="4">
                <a href="http://www.towerhillhomes.ca/" target="_blank">
                  <b-img
                    src="/img/towerhillhomes.png"
                    fluid
                    center
                    alt="Towerhill Homes"
                  />
                </a>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="5">
          <a href="https://goo.gl/maps/6Jkf8ajjGqT89JGf9" target="_blank"
            ><b-img
              fluid
              src="/img/newseaton-keymap.png"
              alt="2820 SIDELINE 26, PICKERING"
              class="keymap"
          /></a>
        </b-col>
      </b-row>
      <div class="d-block d-md-none">
        <b-row>
          <b-col sm="4">
            <a href="http://www.aspenridgehomes.com/" target="_blank">
              <b-img
                src="/img/aspenridgehomesv2.png"
                fluid
                center
                alt="Aspen Ridge Homes"
              />
            </a>
          </b-col>
          <b-col sm="4">
            <a
              href="https://www.brookfieldresidential.com/new-homes/ontario/greater-toronto-area/pickering/new-seaton"
              target="_blank"
            >
              <b-img
                src="/img/brookfieldhomes.png"
                fluid
                center
                alt="Brookfield Homes"
              />
            </a>
          </b-col>
          <b-col sm="4">
            <a href="http://www.towerhillhomes.ca/" target="_blank">
              <b-img
                src="/img/towerhillhomes.png"
                fluid
                center
                alt="Towerhill Homes"
              />
            </a>
          </b-col>
        </b-row>
      </div>

      <div id="m-icons" class="d-block d-md-none">
        <b-row>
          <b-col sm="12" class="text-center">
            <a
              href="https://www.facebook.com/newseatonhomes/"
              target="_blank"
              class=""
            >
              <b-img
                src="/img/icons/facebook_white_circle.png"
                fluid
                width="40"
                class="s-icon "
                alt="facebook"
              />
            </a>
            <a href="https://twitter.com/NewSeaton" target="_blank" class="">
              <b-img
                src="/img/icons/twitter_white_circle.png"
                fluid
                width="40"
                class="s-icon"
                alt="twitter"
              />
            </a>
            <a
              href="https://www.instagram.com/new_seaton/"
              target="_blank"
              class=""
            >
              <b-img
                src="/img/icons/instagram_white_circle.png"
                fluid
                width="40"
                class="s-icon"
                alt="instagram"
              />
            </a>
          </b-col>
        </b-row>
      </div>
      <!-- <b-row align-v="center">
                <b-col offset-sm="3" sm="2">
                    <a href="http://www.aspenridgehomes.com/" target="_blank">
                        <b-img src="/img/aspenridgehomesv2.png" fluid center alt="Aspen Ridge Homes"/>
                    </a>
                </b-col>
                <b-col sm="2">
                    <a href="https://www.brookfieldresidential.com/new-homes/ontario/greater-toronto-area/pickering/new-seaton" target="_blank">
                        <b-img src="/img/brookfieldhomes.png" fluid center alt="Brookfield Homes"/>
                    </a>                    
                </b-col>
                <b-col sm="2">
                    <a href="http://www.towerhillhomes.ca/" target="_blank">
                        <b-img src="/img/towerhillhomes.png" fluid center alt="Towerhill Homes"/>
                    </a>                    
                </b-col>                                
            </b-row> -->
      <b-row>
        <b-col>
          <p class="disclaimer">
            Renderings are artist's concept. Prices, terms and specifications
            subject to change without notice. E. & O. E.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <!-- corona virus alert info modal -->
    <!-- <app-alert /> -->
  </footer>
</template>

<script>
import AppAlert from "@/components/AppAlert";
export default {
  components: {
    // AppAlert
  }
};
</script>

<style scoped></style>
