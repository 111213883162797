import Vue from 'vue'
import VueScrollReveal from 'vue-scroll-reveal';
import VueScrollactive from 'vue-scrollactive';
import Slick from 'vue-slick'
import '~/node_modules/slick-carousel/slick/slick.css'
import '~/node_modules/slick-carousel/slick/slick-theme.css'
import inViewportDirective from 'vue-in-viewport-directive'

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 800,
  scale: 1,
  distance: '30px',
  mobile: true
});

Vue.use(VueScrollactive);
Vue.component('slick', Slick);
Vue.directive('in-viewport', inViewportDirective)