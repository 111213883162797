export const state = () => ({
    blogsShown: 0,
    animationScreen: true,
  })
  
  export const mutations = {
    blogShownCounter (state, n) {
      state.blogsShown += n
    },
    endAnimationScreen (state, payload) {
      state.animationScreen = payload
    }
  }

  export const getters = {
    getBlogsShown(state){
        return state.blogsShown
    },
    getAnimationScreen(state){
      return state.animationScreen
    }
  }

  export const actions = {
    increaseBlogsShown ({ commit}, n) {
      return new Promise((resolve, reject) => {
          commit('blogShownCounter',n)
          resolve()
      })
    }
  }