<template>
<div>
    <b-modal id="alertModal" ref="alertModal" hide-footer>
        <!-- <div style="display:flex;flex-direction:column;justify-content:center;"> -->
        <div class="d-block text-center" style="padding:0;">
            <b-row class="topPart">
                <div class="red Antonio-Bold bigTitle">THANK YOU <br>FOR VISITING</div>
                <div class="alertTopIconContainer"><img class="alertTopIcon fluid" src="/img/alert/Brook_NewSeaton_Disclaimer_Display_031620_03.jpg" /></div>
            </b-row>
            <b-row class="midTitlContainer">
                <b-col class="Gotham-Bold midTitle">
                    <div class="padFirstp">At this time, we are available by appointment only.</div>
                    <div>Get in touch with us to book a one-on-one appointment.</div>
                </b-col>
            </b-row>
            <b-row class="iconContainer">
                <b-col md="6">
                    <div><img class="alertIcon fluid" src="/img/alert/Brook_NewSeaton_Disclaimer_Display_031620_07.jpg" alt="AspenRidge Icon"/></div>
                    <div class="Gotham-Book emailAdd" ><a href="mailto:NSSALES@ASPENRIDGEHOMES.COM" target="_blank">NSSALES@ASPENRIDGEHOMES.COM</a></div>
                    <div class="Gotham-Medium"><a  href="tel:6472993186">647.299.3186</a></div>
                </b-col>
                <b-col md="6">
                    <div><img class="alertIcon fluid" src="/img/alert/Brook_NewSeaton_Disclaimer_Display_031620_09.jpg" alt="Brookfield Residential Icon"/></div>
                    <div class="Gotham-Book emailAdd"><a href="mailto:NEWSEATON@BROOKFIELDRP.COM" target="_blank">NEWSEATON@BROOKFIELDRP.COM</a></div>
                    <div class="Gotham-Medium"><a href="tel:8776643844">877.664.3844</a></div>
                </b-col>
            </b-row>
            <b-row class="iconContainer">
                <b-col md="12">
                    <div><img class="alertIcon fluid" src="/img/alert/Brook_NewSeaton_Disclaimer_Display_031620_14.jpg" alt="Tower Hill Icon"/></div>
                    <div class="Gotham-Book emailAdd"><a href="mailto:SALES@TOWERHILL.CA" target="_blank">SALES@TOWERHILL.CA</a></div>
                    <div class="Gotham-Medium"><a href="tel:6472837480">647.283.7480</a></div>
                </b-col>
            </b-row>
            <div style="background-color:#e6e7e8;padding:30px 35px;margin-top:35px;">
                <div class="red Antonio-Bold footTitle">WE TAKE YOUR HEALTH & SAFETY SERIOUSLY.</div>
                <p class="Gotham-Book">We are closely monitoring developments in COVID-19 globally.</p>
                <p class="Gotham-Book">We are undertaking a number of precautionary measures aimed at preventing the spread of the virus.</p>
                <p class="Gotham-Book">If you have any signs of illness, particularly flu-like symptoms such as fever, cough, shortness of breath and headache, please visit us another time.</p>
                <p class="Gotham-Book">We are all doing our part to prevent the spread of the virus so our guests, colleagues and neighbours remain healthy.</p>
            </div>
            <!-- </div> -->
        </div>
    </b-modal>
</div>
</template>

<script>
export default {
  mounted () {
    $("#btnId").click();
  },
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/fonts";

.red {
    color: #d62c3b;
}

.bigTitle {
    font-size: 75px;
    line-height: 110%;
    text-align: left;
    margin-bottom: 35px;

    @media screen and (max-width: 450px) {
        font-size: 15vw;
    }
    @media screen and (max-width: 650px) and (orientation: landscape) {
        font-size: 15vw;
        width:auto;
    }
    @media screen and (min-width: 451px) and (max-width: 820px) and (orientation: portrait) {
        width:65%;
    }
}
.alertTopIconContainer{
    @media screen and (max-width: 650px) and (orientation: landscape){
        width:auto;
    }
    @media screen and (min-width: 451px) and (max-width: 820px) and (orientation: portrait) {
        width:35%;
    }
}

.padFirstp {
    @media screen and (max-width: 450px) {
        margin-bottom: 10px;
    }
}

.midTitle {
    font-size: 22px;
    margin: 25px 0;
    line-height: 125%;
    @media screen and (max-width: 450px) {
        font-size: 18px;
    }
}

.footTitle {
    font-size: 35px;
    line-height: 125%;
    margin-bottom: 15px;
}

.alertIcon {
    // width:250px;
    margin: 35px 0 0 0;
}

.alertTopIcon {
    width: 250px;
    margin: 0 0 35px 0;
    @media screen and (max-width: 450px) {
        width: 220px;
    }
}

.iconContainer {
    margin: 0 35px;

    @media screen and (max-width: 450px) {
        margin: 0 5px;
    }
}

.midTitlContainer {
    margin: 0 35px;
    background-color: #c7e8f4;

    @media screen and (max-width: 450px) {
        margin: 0 20px;
    }
}

.topPart {
    margin: 0 30px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    @media screen and (max-width: 670px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0 20px;
    }
}

.emailAdd {
    @media screen and (max-width: 450px) {
        font-size: 14px;
    }
}
</style>
<style lang="scss">
#alertModal {
    background-color: rgba(20, 20, 20, 0.5);
}

#alertModal___BV_modal_header_ {
    border-bottom: none;
    border-bottom-color:transparent;
    border-radius: 0;
    height: 50px;
    // display:none;
}

#alertModal___BV_modal_body_ {
    background-color: #fff;
}

#alertModal___BV_modal_footer_ {
    background-color: #fff;

    & .btn:first-child {
        display: none;
    }
}

.modal-dialog {
    max-width: 1000px;
    padding: 0 20px;

    @media screen and (max-width: 570px) and (orientation: landscape) {
        padding: 30px 10px;
    }

    @media screen and (max-width: 480px) and (orientation: portrait) {
        padding: 5px 10px;
    }

    margin-left: auto;
    margin-right: auto;
    // margin:0 auto;
}

.modal-content {
    border-radius: 0;
}
</style>
