export const kebabify = (words) =>
  words
  .replace(/([a-z])([A-Z])/g, '$1-$2')
  .replace(/\s+/g, '-')
  .replace(/\'/g, "")
  .replace(/\./g, "")
  .toLowerCase()
  

export const prettyDate = (date) =>
  new Date(date)
    .toString()
    .split(' ')
    .slice(0, 4)
    .join(' ')
    .replace(/( \d+)$/, ',$1')

export const newLine = (words) => 
  words
  .replace(/\\n/g, '<br>')


export function fadeOut(elem, time ) {
  var to = 1;
  this.animate({
      duration: time.duration,
      delta: function(progress) {
          progress = this.progress;
          return swing(progress);
      },
      complete: time.complete,
      step: function(delta) {
        elem.style.opacity = to - delta;
      }
  });
}

export function fadeIn(elem, time) {
  var to = 0;
  this.animate({
      duration: time.duration,
      delta: function(progress) {
          progress = this.progress;
          return swing(progress);
      },
      complete: time.complete,
      step: function(delta) {
        elem.style.opacity = to + delta;
      }
  });
}

function swing(progress) {
  return 0.5 - Math.cos(progress * Math.PI) / 2;
}

import blogs_json from '@/json/blogs.json';
import Vue from 'vue';

const _blog = blogs_json;
export function getBlog (id, cb) {
    if (_blog[id]) {
      cb(null, _blog[id])
    } else {
      cb(new Error('Event not found.'))
    }
}  

var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);