<template>
    <div>
        <div class="background--black text--white text-center d-block d-md-none register-mobile text--extrabold"><nuxt-link to="/register/" style="color: #fff">REGISTER NOW</nuxt-link></div>
        <header class="topheader">
            <b-container class="h-100">
                 
                <b-row align-v="end" class="h-100">
                    <b-col  md="12" align-self="end">
                        <!-- <div class="d-none d-md-block"> -->
                        <a href="https://www.facebook.com/newseatonhomes/" target="_blank" class="">
                            <b-img src="/img/icons/facebook_red.png" fluid width="40" class="s-icon d-none d-md-inline-block" alt="facebook"/>
                        </a>  
                         <a href="https://twitter.com/NewSeaton" target="_blank" class="">
                            <b-img src="/img/icons/twitter_red.png" fluid width="40" class="s-icon d-none d-md-inline-block" alt="twitter"/>
                        </a>
                         <a href="https://www.instagram.com/new_seaton/" target="_blank" class="">
                            <b-img src="/img/icons/instagram_red.png" fluid width="40" class="s-icon d-none d-md-inline-block" alt="instagram"/>
                        </a>
                        <!-- </div> -->
                        <nuxt-link to="/">
                        <b-img src="/img/icons/newseaton-icon.svg" fluid  width="250" class="toplogo" alt="New Seaton"/>
                        </nuxt-link>
                        <nuxt-link to="/">
                            <b-img src="/img/newseaton-logo.png" class="mb-5 d-none d-md-block newseaton" width="250" fluid right alt="New Seaton | Community Built From The Ground Up"/>
                        </nuxt-link>
                    </b-col>                                     
                </b-row>
            </b-container>
        </header>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>