import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f500cfc8 = () => interopDefault(import('../pages/2024bbq.vue' /* webpackChunkName: "pages/2024bbq" */))
const _005fa6ca = () => interopDefault(import('../pages/2024bbq-thanks.vue' /* webpackChunkName: "pages/2024bbq-thanks" */))
const _539fa4db = () => interopDefault(import('../pages/aspenridgeHomes.vue' /* webpackChunkName: "pages/aspenridgeHomes" */))
const _e4d2434e = () => interopDefault(import('../pages/bf-ns-thankyou.vue' /* webpackChunkName: "pages/bf-ns-thankyou" */))
const _57d5e636 = () => interopDefault(import('../pages/bf-ns-thankyou-test.vue' /* webpackChunkName: "pages/bf-ns-thankyou-test" */))
const _96dad7fc = () => interopDefault(import('../pages/bf-ppc-lp.vue' /* webpackChunkName: "pages/bf-ppc-lp" */))
const _6f1688e8 = () => interopDefault(import('../pages/bf-ppc-lp-Hubspot.vue' /* webpackChunkName: "pages/bf-ppc-lp-Hubspot" */))
const _556c35d5 = () => interopDefault(import('../pages/bf-ppc-lp-old-staging-version.vue' /* webpackChunkName: "pages/bf-ppc-lp-old-staging-version" */))
const _f3a149b0 = () => interopDefault(import('../pages/bf-ppc-lp-staging-v1.vue' /* webpackChunkName: "pages/bf-ppc-lp-staging-v1" */))
const _9aefa7d2 = () => interopDefault(import('../pages/bh-brokerportal.vue' /* webpackChunkName: "pages/bh-brokerportal" */))
const _33a8f100 = () => interopDefault(import('../pages/brookfieldHomes.vue' /* webpackChunkName: "pages/brookfieldHomes" */))
const _55265586 = () => interopDefault(import('../pages/homeowner-fall-bbq.vue' /* webpackChunkName: "pages/homeowner-fall-bbq" */))
const _5b5268b5 = () => interopDefault(import('../pages/homeowner-fall-bbq-thankyou.vue' /* webpackChunkName: "pages/homeowner-fall-bbq-thankyou" */))
const _44681a20 = () => interopDefault(import('../pages/index-Feb1_2023.vue' /* webpackChunkName: "pages/index-Feb1_2023" */))
const _0bae33b7 = () => interopDefault(import('../pages/index-jun-29-2020-backup.vue' /* webpackChunkName: "pages/index-jun-29-2020-backup" */))
const _5105a557 = () => interopDefault(import('../pages/index-old.vue' /* webpackChunkName: "pages/index-old" */))
const _209e9cbc = () => interopDefault(import('../pages/index-old-2.vue' /* webpackChunkName: "pages/index-old-2" */))
const _86469970 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _443b171e = () => interopDefault(import('../pages/register_CM.vue' /* webpackChunkName: "pages/register_CM" */))
const _184e7337 = () => interopDefault(import('../pages/safety.vue' /* webpackChunkName: "pages/safety" */))
const _400a2916 = () => interopDefault(import('../pages/singles.vue' /* webpackChunkName: "pages/singles" */))
const _3a0163dc = () => interopDefault(import('../pages/sitetable.vue' /* webpackChunkName: "pages/sitetable" */))
const _dd87cecc = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _6efdabf2 = () => interopDefault(import('../pages/thankyou-bf.vue' /* webpackChunkName: "pages/thankyou-bf" */))
const _449af9f0 = () => interopDefault(import('../pages/thankyou-ns.vue' /* webpackChunkName: "pages/thankyou-ns" */))
const _31d1efa2 = () => interopDefault(import('../pages/towerhillHomes.vue' /* webpackChunkName: "pages/towerhillHomes" */))
const _3d876fea = () => interopDefault(import('../pages/blogs/a-new-fitter-healthier-you.vue' /* webpackChunkName: "pages/blogs/a-new-fitter-healthier-you" */))
const _2ed81ce6 = () => interopDefault(import('../pages/blogs/activities-at-petticoat.vue' /* webpackChunkName: "pages/blogs/activities-at-petticoat" */))
const _9ca3db3c = () => interopDefault(import('../pages/blogs/adorable-antique-shops-in-the-pickering-area.vue' /* webpackChunkName: "pages/blogs/adorable-antique-shops-in-the-pickering-area" */))
const _204fa05a = () => interopDefault(import('../pages/blogs/college-close-to-home.vue' /* webpackChunkName: "pages/blogs/college-close-to-home" */))
const _4da35c5c = () => interopDefault(import('../pages/blogs/enjoy-life-on-the-water-near-new-seaton.vue' /* webpackChunkName: "pages/blogs/enjoy-life-on-the-water-near-new-seaton" */))
const _023826ca = () => interopDefault(import('../pages/blogs/event-listing-artfest-on-the-esplanade.vue' /* webpackChunkName: "pages/blogs/event-listing-artfest-on-the-esplanade" */))
const _0e0cda5f = () => interopDefault(import('../pages/blogs/five-must-play-golf-courses-in-pickering.vue' /* webpackChunkName: "pages/blogs/five-must-play-golf-courses-in-pickering" */))
const _97470152 = () => interopDefault(import('../pages/blogs/getting-around-at-new-seaton.vue' /* webpackChunkName: "pages/blogs/getting-around-at-new-seaton" */))
const _3d593b57 = () => interopDefault(import('../pages/blogs/great-grown-up-birthday-party-ideas-in-pickering.vue' /* webpackChunkName: "pages/blogs/great-grown-up-birthday-party-ideas-in-pickering" */))
const _1e688ca7 = () => interopDefault(import('../pages/blogs/higher-living-meets-high-education.vue' /* webpackChunkName: "pages/blogs/higher-living-meets-high-education" */))
const _2ebc2d7c = () => interopDefault(import('../pages/blogs/march-break-madness-activities-kids-will-love.vue' /* webpackChunkName: "pages/blogs/march-break-madness-activities-kids-will-love" */))
const _3eb94a2e = () => interopDefault(import('../pages/blogs/new-approach-to-complete-community-design.vue' /* webpackChunkName: "pages/blogs/new-approach-to-complete-community-design" */))
const _b254171c = () => interopDefault(import('../pages/blogs/new-seaton-featured-in-the-toronto-sun.vue' /* webpackChunkName: "pages/blogs/new-seaton-featured-in-the-toronto-sun" */))
const _caa31a3e = () => interopDefault(import('../pages/blogs/new-ways-to-have-fun-this-family-day-in-pickering.vue' /* webpackChunkName: "pages/blogs/new-ways-to-have-fun-this-family-day-in-pickering" */))
const _457f0877 = () => interopDefault(import('../pages/blogs/our-5-favourite-things-to-do-at-rouge-national-urban-park.vue' /* webpackChunkName: "pages/blogs/our-5-favourite-things-to-do-at-rouge-national-urban-park" */))
const _795a2de6 = () => interopDefault(import('../pages/blogs/petapolooza-pickering.vue' /* webpackChunkName: "pages/blogs/petapolooza-pickering" */))
const _32354390 = () => interopDefault(import('../pages/blogs/pickering-summer-concert-series.vue' /* webpackChunkName: "pages/blogs/pickering-summer-concert-series" */))
const _fb2bab64 = () => interopDefault(import('../pages/blogs/six-spots-you-need-to-try-in-downtown-pickering.vue' /* webpackChunkName: "pages/blogs/six-spots-you-need-to-try-in-downtown-pickering" */))
const _503aedb0 = () => interopDefault(import('../pages/blogs/the-best-community-facilities-near-new-seaton.vue' /* webpackChunkName: "pages/blogs/the-best-community-facilities-near-new-seaton" */))
const _0ffca1d6 = () => interopDefault(import('../pages/blogs/the-best-micro-breweries.vue' /* webpackChunkName: "pages/blogs/the-best-micro-breweries" */))
const _8f9253fa = () => interopDefault(import('../pages/blogs/top-shopping.vue' /* webpackChunkName: "pages/blogs/top-shopping" */))
const _003b84ff = () => interopDefault(import('../pages/blogs/top-vendors-at-pickering-farmers-market.vue' /* webpackChunkName: "pages/blogs/top-vendors-at-pickering-farmers-market" */))
const _5d933b32 = () => interopDefault(import('../pages/blogs/whats-new-at-the-zoo.vue' /* webpackChunkName: "pages/blogs/whats-new-at-the-zoo" */))
const _b2d371b6 = () => interopDefault(import('../pages/highlight/award-winning-builders.vue' /* webpackChunkName: "pages/highlight/award-winning-builders" */))
const _4cbf6bac = () => interopDefault(import('../pages/highlight/community-vision.vue' /* webpackChunkName: "pages/highlight/community-vision" */))
const _43ae05b8 = () => interopDefault(import('../pages/highlight/new-home-types.vue' /* webpackChunkName: "pages/highlight/new-home-types" */))
const _635e642c = () => interopDefault(import('../pages/highlight/new-parks.vue' /* webpackChunkName: "pages/highlight/new-parks" */))
const _02457d60 = () => interopDefault(import('../pages/highlight/new-retail-shops.vue' /* webpackChunkName: "pages/highlight/new-retail-shops" */))
const _1758ac22 = () => interopDefault(import('../pages/highlight/new-schools.vue' /* webpackChunkName: "pages/highlight/new-schools" */))
const _5a18885a = () => interopDefault(import('../pages/highlight/new-trails.vue' /* webpackChunkName: "pages/highlight/new-trails" */))
const _55534476 = () => interopDefault(import('../pages/highlight/new-transit.vue' /* webpackChunkName: "pages/highlight/new-transit" */))
const _2b9cb9fd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/2024bbq",
    component: _f500cfc8,
    name: "2024bbq"
  }, {
    path: "/2024bbq-thanks",
    component: _005fa6ca,
    name: "2024bbq-thanks"
  }, {
    path: "/aspenridgeHomes",
    component: _539fa4db,
    name: "aspenridgeHomes"
  }, {
    path: "/bf-ns-thankyou",
    component: _e4d2434e,
    name: "bf-ns-thankyou"
  }, {
    path: "/bf-ns-thankyou-test",
    component: _57d5e636,
    name: "bf-ns-thankyou-test"
  }, {
    path: "/bf-ppc-lp",
    component: _96dad7fc,
    name: "bf-ppc-lp"
  }, {
    path: "/bf-ppc-lp-Hubspot",
    component: _6f1688e8,
    name: "bf-ppc-lp-Hubspot"
  }, {
    path: "/bf-ppc-lp-old-staging-version",
    component: _556c35d5,
    name: "bf-ppc-lp-old-staging-version"
  }, {
    path: "/bf-ppc-lp-staging-v1",
    component: _f3a149b0,
    name: "bf-ppc-lp-staging-v1"
  }, {
    path: "/bh-brokerportal",
    component: _9aefa7d2,
    name: "bh-brokerportal"
  }, {
    path: "/brookfieldHomes",
    component: _33a8f100,
    name: "brookfieldHomes"
  }, {
    path: "/homeowner-fall-bbq",
    component: _55265586,
    name: "homeowner-fall-bbq"
  }, {
    path: "/homeowner-fall-bbq-thankyou",
    component: _5b5268b5,
    name: "homeowner-fall-bbq-thankyou"
  }, {
    path: "/index-Feb1_2023",
    component: _44681a20,
    name: "index-Feb1_2023"
  }, {
    path: "/index-jun-29-2020-backup",
    component: _0bae33b7,
    name: "index-jun-29-2020-backup"
  }, {
    path: "/index-old",
    component: _5105a557,
    name: "index-old"
  }, {
    path: "/index-old-2",
    component: _209e9cbc,
    name: "index-old-2"
  }, {
    path: "/register",
    component: _86469970,
    name: "register"
  }, {
    path: "/register_CM",
    component: _443b171e,
    name: "register_CM"
  }, {
    path: "/safety",
    component: _184e7337,
    name: "safety"
  }, {
    path: "/singles",
    component: _400a2916,
    name: "singles"
  }, {
    path: "/sitetable",
    component: _3a0163dc,
    name: "sitetable"
  }, {
    path: "/thankyou",
    component: _dd87cecc,
    name: "thankyou"
  }, {
    path: "/thankyou-bf",
    component: _6efdabf2,
    name: "thankyou-bf"
  }, {
    path: "/thankyou-ns",
    component: _449af9f0,
    name: "thankyou-ns"
  }, {
    path: "/towerhillHomes",
    component: _31d1efa2,
    name: "towerhillHomes"
  }, {
    path: "/blogs/a-new-fitter-healthier-you",
    component: _3d876fea,
    name: "blogs-a-new-fitter-healthier-you"
  }, {
    path: "/blogs/activities-at-petticoat",
    component: _2ed81ce6,
    name: "blogs-activities-at-petticoat"
  }, {
    path: "/blogs/adorable-antique-shops-in-the-pickering-area",
    component: _9ca3db3c,
    name: "blogs-adorable-antique-shops-in-the-pickering-area"
  }, {
    path: "/blogs/college-close-to-home",
    component: _204fa05a,
    name: "blogs-college-close-to-home"
  }, {
    path: "/blogs/enjoy-life-on-the-water-near-new-seaton",
    component: _4da35c5c,
    name: "blogs-enjoy-life-on-the-water-near-new-seaton"
  }, {
    path: "/blogs/event-listing-artfest-on-the-esplanade",
    component: _023826ca,
    name: "blogs-event-listing-artfest-on-the-esplanade"
  }, {
    path: "/blogs/five-must-play-golf-courses-in-pickering",
    component: _0e0cda5f,
    name: "blogs-five-must-play-golf-courses-in-pickering"
  }, {
    path: "/blogs/getting-around-at-new-seaton",
    component: _97470152,
    name: "blogs-getting-around-at-new-seaton"
  }, {
    path: "/blogs/great-grown-up-birthday-party-ideas-in-pickering",
    component: _3d593b57,
    name: "blogs-great-grown-up-birthday-party-ideas-in-pickering"
  }, {
    path: "/blogs/higher-living-meets-high-education",
    component: _1e688ca7,
    name: "blogs-higher-living-meets-high-education"
  }, {
    path: "/blogs/march-break-madness-activities-kids-will-love",
    component: _2ebc2d7c,
    name: "blogs-march-break-madness-activities-kids-will-love"
  }, {
    path: "/blogs/new-approach-to-complete-community-design",
    component: _3eb94a2e,
    name: "blogs-new-approach-to-complete-community-design"
  }, {
    path: "/blogs/new-seaton-featured-in-the-toronto-sun",
    component: _b254171c,
    name: "blogs-new-seaton-featured-in-the-toronto-sun"
  }, {
    path: "/blogs/new-ways-to-have-fun-this-family-day-in-pickering",
    component: _caa31a3e,
    name: "blogs-new-ways-to-have-fun-this-family-day-in-pickering"
  }, {
    path: "/blogs/our-5-favourite-things-to-do-at-rouge-national-urban-park",
    component: _457f0877,
    name: "blogs-our-5-favourite-things-to-do-at-rouge-national-urban-park"
  }, {
    path: "/blogs/petapolooza-pickering",
    component: _795a2de6,
    name: "blogs-petapolooza-pickering"
  }, {
    path: "/blogs/pickering-summer-concert-series",
    component: _32354390,
    name: "blogs-pickering-summer-concert-series"
  }, {
    path: "/blogs/six-spots-you-need-to-try-in-downtown-pickering",
    component: _fb2bab64,
    name: "blogs-six-spots-you-need-to-try-in-downtown-pickering"
  }, {
    path: "/blogs/the-best-community-facilities-near-new-seaton",
    component: _503aedb0,
    name: "blogs-the-best-community-facilities-near-new-seaton"
  }, {
    path: "/blogs/the-best-micro-breweries",
    component: _0ffca1d6,
    name: "blogs-the-best-micro-breweries"
  }, {
    path: "/blogs/top-shopping",
    component: _8f9253fa,
    name: "blogs-top-shopping"
  }, {
    path: "/blogs/top-vendors-at-pickering-farmers-market",
    component: _003b84ff,
    name: "blogs-top-vendors-at-pickering-farmers-market"
  }, {
    path: "/blogs/whats-new-at-the-zoo",
    component: _5d933b32,
    name: "blogs-whats-new-at-the-zoo"
  }, {
    path: "/highlight/award-winning-builders",
    component: _b2d371b6,
    name: "highlight-award-winning-builders"
  }, {
    path: "/highlight/community-vision",
    component: _4cbf6bac,
    name: "highlight-community-vision"
  }, {
    path: "/highlight/new-home-types",
    component: _43ae05b8,
    name: "highlight-new-home-types"
  }, {
    path: "/highlight/new-parks",
    component: _635e642c,
    name: "highlight-new-parks"
  }, {
    path: "/highlight/new-retail-shops",
    component: _02457d60,
    name: "highlight-new-retail-shops"
  }, {
    path: "/highlight/new-schools",
    component: _1758ac22,
    name: "highlight-new-schools"
  }, {
    path: "/highlight/new-trails",
    component: _5a18885a,
    name: "highlight-new-trails"
  }, {
    path: "/highlight/new-transit",
    component: _55534476,
    name: "highlight-new-transit"
  }, {
    path: "/",
    component: _2b9cb9fd,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
